.profile-input-border {
  border: 1px solid var(--na-lite-gray1);
}

.profile-edit-bg-color {
  background-color: var(--na-primary-color);
}

.font-size-14 {
  font-size: 14px;
}
.password-border {
  border: 1px solid var(--na-lite-gray) !important;
}

.select.select-smd {
  height: 2.2rem;
  min-height: 2.2rem;
}

select.select:focus {
  outline: none !important;
}

select,
select:active,
select:visited,
select:focus {
  outline: none !important;
}

.text-font {
  font-family: var(--na-secondary-font);
}

.upload-photo {
  bottom: 4px;
  right: 22px;
  background-color: #fff;
  border-radius: 100px;
}

.address-field div[data-testid='input-select-wrapper'] {
  width: 100%;
}
.address-field {
  margin-top: 10px;
}
@media (min-width: 767px) {
  .address-field-textarea {
    width: 97.5%;
  }
  .address-field {
    display: flex;
    flex-direction: column;
    width: 95%;
    align-items: start;
    justify-content: center;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  /*visibility: hidden; */
  display: none;
  background-color: white;
  color: black;
  text-align: justify;
  border-radius: 6px;
  border-color: #cfc7c7;
  border-width: 0.1px;
  line-height: normal;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  font-size: 12px;
  padding: 13px;
  min-width: 280px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  display: flex;
}

.tooltip .tooltiptext.left-200 {
  left: -200px;
}
.tooltip .tooltiptext.left-80 {
  left: -80px;
}

.profile-image-border {
  border: 3px solid #fff;
}
.text-area::placeholder {
  color: var(--na-secondary-color-hover);
  opacity: 0.5;
}
.thumbnail-class .react-player__shadow {
  height: 165px !important;
}
.sideBar-profile-class {
  min-height: calc(100vh - 150px);
}
.step-button {
  padding: 6px 32px 6px 32px !important;
}
.resource-input {
  background-color: var(--na-lite-grey8);
}

.avatar-hover:hover .menu-drop-hover,
.menu-drop-hover:focus-within {
  visibility: visible;
  opacity: 1;
  top: 2.7rem;
}
.avatar-hover:hover .dropdown-content.menu-drop-hover {
  inset-inline-end: -12px;
}

.dropdown button:hover {
  background-color: var(--na-primary-color-hover);
  color: #ffffff;
}
@media (max-width: 600px) {
  .user-role h5 {
    max-width: 145px;
  }
  .th-container {
    margin: 0 auto;
    min-height: 85vh;
    width: auto;
  }
  button.button {
    display: inline-block;
    margin-top: 10px;
  }
  .tabs button.tab {
    justify-content: start;
    display: flex;
    min-height: 40px;
  }
}
