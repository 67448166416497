.recommendations {
  position: absolute;
  background-color: #fff;
  width: 100%;
  padding: 10px;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 0px 0px 10px #ddd;
  z-index: 999;
}

.bgW-sec input[type='text'] {
  height: 3rem;
  min-height: 3rem;
}

.btn-cancel {
  padding: 4px 20px;
  border-radius: 25px;
  margin-right: 10px;
  margin-left: 10px;
}

.border-zip-table {
  border-left: 2px solid var(--na-primary-color-2);
  border-right: 2px solid var(--na-primary-color-2);
}
.border-zip-bottom {
  border-bottom: 1px solid var(--na-primary-color-2);
}

.zip-list select {
  border: 1px solid #d8d8d8;
  border-radius: 6px;
  padding: 7px 7px;
}
.border-zip-table,
.zip-list select,
.zip-list textarea,
.zip-list input {
  color: #9a9a9a;
}

.local-area-zip {
  border-radius: 6px;
  padding-left: 0.875rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
  box-shadow: 0px -1px 9px 1px rgba(0, 0, 0, 0.1);
}
