.login-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.signin-form {
  width: 85%;
  margin: 0 auto;
}
.corner {
  height: 100%;
  display: flex;
  align-items: center;
}
.corner:before {
  content: '';
  width: 20px;
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: -15px;
  border-radius: 60px 0px 0px 60px;
}
.line-between {
  position: relative;
}
.line-between:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #bebebe;
  top: 50%;
}
.line-between:after {
  content: '';
  position: absolute;
  width: 45%;
  height: 50px;
  background-color: #fff;
  top: 0%;
  margin: 0 auto;
  left: 0;
  right: 0;
}
.signin-footer {
  position: absolute;
  bottom: 40px;
}

@media (max-width: 767px) {
  .signin-form {
    width: 100%;
    margin: 0 auto;
  }
  .line-between:after {
    width: 70%;
  }
  .login-bg:after {
    background-color: #ffffffbf;
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
  }
  .login-bg {
    position: relative;
  }
  .login-bg img,
  .login-bg svg {
    z-index: 2;
    max-width: 200px;
  }
  .corner:before {
    width: 100%;
    height: 20px;
    top: -17px;
    left: 0px;
    border-radius: 60px 60px 0px 0px;
  }
  .signin-footer {
    position: relative;
    bottom: auto;
  }
}
@media (min-width: 1800px) {
  .signin-form {
    width: 75%;
    margin: 0 auto;
  }
  .login-bg {
    background-size: 155%;
  }
}
.common-scrollbar::-webkit-scrollbar {
  width: 5px;
}
.common-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--na-gray2);
  border-radius: 10px;
}

@media (max-width: 1800px) and (min-width: 1000px) and (orientation: landscape) {
  .common-scrollbar {
    overflow-y: scroll;
    height: 480px;
    padding-right: 10px;
  }
}
input::placeholder {
  color: var(--na-gray1) !important;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
@media (min-width: 1801px) {
  .common-scrollbar {
    overflow-y: visible; /* No scrollbar */
    height: auto; /* No height restriction */
    padding-right: 0; /* Remove padding if needed */
  }
}
@media (min-width: 1300px) {
  .title-complete svg {
    margin: 20px auto 0 auto;
  }

  .title-complete {
    text-align: center;
    width: 100%;
  }

  .footer-complete .success {
    margin: 0 auto;
  }

  .footer-complete {
    width: 100%;
    position: relative;
  }
}

.divide-hr {
  background-color: var(--na-primary-color);
}
.phone-component .PhoneInputInput {
  color: var(--na-secondary-color-hover);
  width: -webkit-fill-available;
  display: block;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  padding: 0rem 0.6rem 0rem 1rem;
  outline: none;
  border-radius: 10rem;
  height: 3rem;
  background-color: var(--na-lite-gray2);
}
.preference-phone .PhoneInputInput {
  border: 1px solid var(--na-lite-gray1);
  height: 2.5rem;
  padding-left: 1rem;
}
.profile-details input[type='tel']:focus {
  outline: none;
  border: none;
}
@media (max-width: 767px) {
  .mobile-footer {
    background-color: #fff;
    bottom: 0px;
    position: fixed;
    padding: 5px;
  }
}
