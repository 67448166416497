@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --na-primary-color: #d9622a;
  --na-primary-color-2: #ff9b6b;
  --na-primary-color-3: #FFAD00;
  --na-primary-color-hover: #d9622a;
  --na-primary-color-active: #c23f01;
  --na-primary-lite: #fff7ec;
  --na-primary-color-hover-lite: #ffb390;
  --na-primary-color-active-lite: #ffdccc;
  --na-secondary-color: #323232;
  --na-secondary-color-hover: #5c5c5c;
  --na-primary-color-2: #ff9b6b;
  --na-tertiary-color: #2f097c;
  --na-tertiary-color-hover: #3b34c9;
  --na-tertiary-color-active: #3e6baf;
  --na-tertiary-color-lite: #fff5df;
  --na-tertiary-color-medium: #ffe9de;

  --na-critical: #eb5a51;
  --na-critical-lite: #fde9e7;
  --na-warning: #ffa117;
  --na-warning-lite: #fff4e5;
  --na-success: #5cb747;
  --na-success-lite: #ecffdf;
  --na-info: #0dcaf0;
  --na-info-lite: #94edff;
  --na-wait: #e6ae61;
  --na-wait-lite: #fff9c6;
  --na-green-light: #d9ffb4;
  --na-green-dark: #678100;

  --na-lite-cyan: #f2fdfb;
  --na-lite-orange: #fff4e5;
  --na-gray: #494949;
  --na-gray1: #b7b7b7;
  --na-gray2: #dedfe3;
  --na-gray3: #d9d9d9;
  --na-gray4: #e6e6e6;
  --na-gray5: #c0c0c0;
  --na-lite-gray: #9a9a9a;
  --na-lite-gray1: #ccc2b8;
  --na-lite-gray2: #f1f1f1;
  --na-lite-gray3: #d8d8d8;
  --na-lite-gray4: #888686;
  --na-lite-gray5: #e2e2e2;
  --na-lite-gray6: #949494;
  --na-lite-blue: #f8fbff;
  --na-lite-gray7: #a9a9a9;
  --na-lite-grey8: #f9f9f9;
  --na-lite-grey9: #455a64;
  --na-transparent: transparent;
  --na-default: #828282;
  --na-neutral: #6c757d;
  --na-black: #000;
  --na-lite-black: #3d3d3d;
  --na-white: #fff;
  --na-red: #ff0000;

  --na-primary-font: 'Lato', sans-serif;
  --na-secondary-font: MotelCalifornia, sans-serif;
  --na-font-size: 1rem;
  --na-font-weight: 400;
  --na-line-height: 1.5;
  --na-body-color: #2f2e41;
  --na-body-background: #fff;
  --na-border-width: 1px;
  --na-border-style: solid;
  --na-link-color: #368ac8;
  --na-background: #fff;
  --na-family-member: #f1b434;
  --na-family-member-dark: #ffad00;
  --na-dark-grey: #323233;
  --na-family-lite-member: #feca5d;
  --na-accept-green: #7fb154;
  --na-disabled: #efefef4c;
}
